






























































































































































.message-badge {
  position: relative;
  top: -0.1rem !important;
}

#message-count {
    vertical-align: middle;
}

.message-circle {
    position: absolute;
    bottom: 10px;
    left: 14px;
}
