






















































































































































button, button:hover {
  background-color: transparent;
  border: solid 1px transparent;
}
button:focus{
  outline:none !important;
}
.error {
  color: inherit !important;
}
.legacy-severity {
  font-size: 1.15rem;
  position: absolute;
  left: -40px !important;
  top: -20px !important;
}
.severity-indicator {
  border-radius: 1rem;
  width: .35rem;
  padding: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
}
.severity-indicator.info {
  background-color: #5bc0de !important;
}
.severity-indicator.warn {
  background-color: #e99002 !important;
}
.severity-indicator.crit {
  background-color: #f04124 !important;
}
.card-info {
  text-align: left;
  padding: 1px;
  border: solid 5px transparent;
  margin-bottom: 5px;
}
.sheet {
  border-radius: 0;
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%);
}
.card.sheet {
  padding: 20px 40px;
}
.card {
  display: block;
  width: 310px;
  min-height: 110px;
  max-height: 150px;
  outline: none;
  text-decoration: none;
  transition-property: box-shadow, opacity;
  overflow-wrap: break-word;
  position: relative;
  white-space: normal;
}
.card {
  border-radius: 0px 8px 8px 0px;
}
.card-actions {
  align-items: center;
  display: flex;
  padding: 8px;
  color: lightslategray;
}
