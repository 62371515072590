

























































































li {
  position: relative;
  cursor: pointer;
  background-color: white;
  z-index: 20;
}
.active {
  --text-opacity: 1 !important;
  box-shadow: 0 5px 0 rgba(59, 130, 246, var(--text-opacity));
}
.inactive {
  --text-opacity: 1 !important;
  color: rgba(156, 163, 175, var(--text-opacity)) !important;
}
