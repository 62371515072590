













































































button, button:hover {
  background-color: transparent;
  border: solid 1px transparent;
}
button:focus{
  outline:none !important;
}
