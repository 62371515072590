











































































































.snotifyToast__inner {
  min-height: 60px !important;
  font-size: 12px !important;
}
.snotifyToast__title {
  font-size: 1.3em !important;
  font-weight: 700 !important;
}
.snotifyToast__body {
  font-size: 1em !important;
}
.snotify {
  width: 25rem !important;
}
.snotify-info {
  background-color: #5bc0de !important;
}
.snotify-warning {
  background-color: #e99002 !important;
}
.snotify-error {
  background-color: #f04124 !important;
}
