










































































































































































































































































































.notification-badge {
  position: relative;
  top: -0.1rem !important;
  left: 0.6rem !important;
}
#notificationBell {
  position: relative !important;
  top: -0.060rem !important;
  right: 0.40rem !important;
}
.notification-circle {
  position: absolute;
  bottom: 10px;
  left: 14px;
}
.notification-list {
  text-align: center;
  border-radius: 8px;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.notification-inbox-link {
  text-align: center;
  padding: 10px;
  margin-top: 15px;
}

.v-list, .list {
  min-height: 10px;
  max-height: 500px;
  overflow-x: auto;
}
.menu {
  min-width: 24px;
  width: 350px;
  top: 105%;
  right: 0;
  transform-origin: left top;
  z-index: 1;
}
.menu-content {
  position: absolute;
  overflow-y: auto;
  overflow-x: hidden;
  contain: content;
  will-change: transform;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  border-radius: 8px;
}
