





























.tab {
  display: inline-block;
  color: black;
  padding: 20px;
  min-width: 300px;
  border-radius: 10px;
  min-height: 24px;
  margin-top: 11px;
}
