





































































































.infoIcon {
  color: grey;
}

.infoTooltip {
  position: relative;
  display: inline-block;
  vertical-align: bottom;
  text-align: center;
  height: 100%;
}

.infoTooltip .infoToolTipText {
  visibility: hidden;
  max-width: 350px;
  min-width: 150px;
  background-color: grey;
  color: #fff;
  font-size: 14px;
  text-align: left;
  border-radius: 6px;
  padding: 10px;
  margin-left: 20px;

  position: absolute;
  z-index: 1;
  white-space: pre-line;
}

.infoTooltip .infoToolTipText.direction-top {
  bottom: 0;
  transform: translate(-50%, 0);
}

.infoTooltip .infoToolTipText.direction-left {
  right: 0;
  transform: translate(0, -50%);
}

.infoTooltip:hover .infoToolTipText {
  visibility: visible
}

.infoTooltip .infoToolTipText.direction-top-left {
  bottom: 0;
  transform: translate(-50%, 0);
}

.infoTooltip .infoToolTipText.direction-top-right {
  bottom: 0;
  transform: translate(0, -50%);
}

.infoTooltip .infoToolTipText.direction-left-top {
  right: 0;
  transform: translate(-50%, 0);
}

.infoTooltip .infoToolTipText.direction-left-bottom {
  right: 0;
  transform: translate(-50%, -50%);
}

.infoTooltip .infoToolTipText.direction-right-top {
  right: 0;
  transform: translate(50%, 0);
}

.infoTooltip .infoToolTipText.direction-right-bottom {
  right: 0;
  transform: translate(100%, 0);
}

